<template>
  <div class="nu">
    <img :src="data.url + 'dealer/nu/nu_bg.png'" alt="" class="bg" />
    <div class="video">
      <video
        ref="myVideo"
        width="100%"
        style="object-fit: contain"
        preload
        controls="controls"
        poster="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/nuVideoImg.png"
        src="https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/nu/nu.mp4"
      ></video>
    </div>
    <div class="downLoadBtn" @click="lookVideo">
      分享和下载视频
      <img :src="data.url + 'right.png'" alt="" />
    </div>
    <div class="row">
      <div class="col" @click="openPdf">
        <div class="coverImg">
          <img :src="data.url + 'dealer/nu/activity.png'" alt="" />
        </div>
        <div class="nav">
          <div class="text">活动相册</div>
          <img :src="data.url + 'right.png'" alt="" />
        </div>
      </div>
      <div class="col" @click="jumpPath">
        <div class="coverImg">
          <img :src="data.url + 'dealer/nu/tuwen.png'" alt="" />
        </div>
        <div class="nav">
          <div class="text">图文回顾</div>
          <img :src="data.url + 'right.png'" alt="" />
        </div>
      </div>
    </div>
    <div class="more">更多NU星人活动敬请期待！</div>
    <div class="backBtn" @click="backIndex">
      <img :src="data.url + 'dealer/nu/backBtn.png'" alt="" />
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { prviewVideoBox } from "../../js/until";
import { reactive } from "vue";
// import { showToast } from "vant";
// import { baiduTotal } from "../js/common.js";
let data = reactive({
  url: "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/", //图片地址
});
const router = useRouter();
// 返回首页
let backIndex = () => {
  router.replace("/index");
};
//查看视频
let lookVideo = () => {
  prviewVideoBox({
    videoUrl:
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/nu/nu.mp4",
    qcodeUrl:
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/dealer/nu/nuqr.png",
    poster:
      "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/videoImg/nuVideoImg.png",
    wangpanPath:
      "https://nudrive.cn.nuskin.com/file/4mqwo5igy49glbxpcusoi8e2xj5ev87y#",
  });
};
//页面跳转
let jumpPath = () => {
  window.location.href = "https://mp.weixin.qq.com/s/5xOMkMgDa-_w_7ILtEjxRg";
};
//活动相册
let openPdf = () => {
  window.location.href =
    "https://as.alltuu.com/album/1616315646/1265727402/?from=qrCode&menu=live";
};
</script>

<style lang="scss" scoped>
.nu {
  width: 100%;
  height: 100%;
  position: relative;
  background: #fdb01f;
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 0;
  img {
    width: 100%;
  }
  .video {
    width: 100%;
  }
  .downLoadBtn {
    position: relative;
    width: 1.52rem;
    height: 0.4rem;
    margin-top: 0.14rem;
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: PingFang SC;
    font-size: 0.12rem;
    font-style: normal;
    font-weight: 400;
    border-radius: 0.2rem;
    img {
      width: 0.16rem;
    }
  }
  .row {
    width: 100%;
    padding: 0 0.12rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.2rem;
    .col {
      width: 1.7rem;
      border-radius: 0.08rem;
      background: linear-gradient(90deg, #ff770a, #ffb945, #ff770a);
      box-shadow: 0 0.04rem 0.1rem rgba($color: #ff770a, $alpha: 0.3);
      .coverImg {
        width: 100%;
        font-size: 0;
        img {
          width: 100%;
          border-radius: 0.08rem 0.08rem 0 0;
        }
      }
      .nav {
        width: 100%;
        height: 0.48rem;
        font-size: 0;
        border-radius: 0 0 0.08rem 0.08rem;
        padding-left: 0.16rem;
        padding-right: 0.2rem;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .text {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 12px;
          color: #ffffff;
        }
        img {
          width: 0.24rem;
        }
      }
    }
  }
  .more {
    font-size: 0.14rem;
    font-weight: bold;
    color: #fff;
    margin: 0.3rem auto;
    text-align: center;
  }
}
</style>
